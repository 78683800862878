import * as React from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import Gallery from "../components/Gallery";

const WeddingPage = () => (
  <Layout descriptionText={"Wedding"}>
    <Seo title="wedding" />

    <Gallery categoryName="wedding" />
  </Layout>
);

export default WeddingPage;
